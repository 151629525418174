import React, { Component } from 'react';

import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Listings extends Component {
	render() {
		return (
			<div>
				{this.props.store.renewal ?
					<h3>Current Listings</h3>
					:
					<h3>Listings</h3>
				}
				<p><strong>Group Discount:</strong> If you pay for two or more listings you will qualify for a group discount (normally $150, group price $120).</p>
				{this.props.store.renewal ?
					"Select the practitioners that you wish to complete payment for today:"
					:
					"Enter the name of the practitioners below. If you wish to add more than one listing, then click ‘Add Another Listing’."
				}
				<table className="doctors-table" >
					<thead>
						<tr>
							<td className="check"></td>
							<td></td>
							<td></td>
							<td className="status"></td>
							<td className="price"></td>
						</tr>
					</thead>
					<tbody>
						{this.props.store.doctors.map((doctor,index) =>
							<TrDoctor
								key={index}
								index={index}
								doctor={doctor}
								store={this.props.store}
							/>)}
					</tbody>
				</table>
				<div className="btn-add" onClick={this.props.store.addPsych}>Add Another Listing</div>
			</div>
		);
	}
}


const TrDoctor = observer(({ store, doctor, index }) =>
<tr>
	<td>
		{doctor.accountStatus === 'paid' ? null :
				<input type="checkbox" checked={doctor.check} onChange={e => doctor.check = e.target.checked} />
		 }
	</td>
	<td></td>
		<td className="name">
			{!doctor.editable ?
				doctor.name
				:
				<input
					type="text"
					placeholder="John Smith"
					autoFocus
					value={doctor.name}
					onChange={e => doctor.name = e.target.value} />}
		</td>
	<td >
			<div className={`label label-${doctor.accountStatus === 'paid' ? "success" : "warning"}`}>
			{doctor.accountStatus}
		</div>
	</td>
		<td >
		{doctor.accountStatus === "paid" ? null :<span>
			${(store.price/100).toFixed(2)}
		</span> }
		{doctor.editable && <div className="icon" onClick={() => store.removePsych(index)}>x</div>}
	</td>
</tr>)


export default Listings;