import React, { Component } from 'react';
import TaxInvoice from './TaxInvoice'

class ThankYou extends Component {
	render() {
		return (
			<div className="checkout-holder">
				<h2>Thank you for your renewal</h2>
				<p>We have received your payment and updated your listings to ensure they will be published for the 2020 edition. </p>
				<p>An email will be sent to you with your receipt.</p>
				<p>The email will also have links to update your listings if you have any changes to make such as updated photos, numbers, etc.</p>
				<TaxInvoice />
			</div>
		);
	}
}

export default ThankYou;