import React, { Component } from 'react';
import './Payment.scss'
import Listings from './Listings'
import Checkout from './Checkout'
import ThankYou from './ThankYou'

import Redirected from './Redirected'

import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class Payment extends Component {
	render() {
		return (
			<div className="payment">
				<div className="payment-holder">
					<div className="logo"></div>
					{this.props.store.redirected ?
					<Redirected />
					: 
					<div>
						{this.props.store.renewal ?
							<Introduction />
							:
							<IntroductionNewListing />
						}
						<Listings />
						{(this.props.store.cost && !this.props.store.showTaxInvoice) ? <Checkout /> : null}
						{this.props.store.showTaxInvoice ? <ThankYou /> : null}
					</div>}
				</div>
			</div>
		);
	}
}

export default Payment;

const IntroductionNewListing =()=>
	<div>
		<h1>2020 Psychology and Mental Health Directory</h1>
		<p>This page is for practitioners wishing to have their listing in the 2020 directory which is produced both in print and online for distribution to GPs across WA.</p>
		
		<p><strong>After you complete payment, you will receive an email with an invoice and links to update or edit listing details. Please use those links to make changes. </strong></p>
	</div>


const Introduction =()=>
	<div>
		<h1>2020 Renewal Summary</h1>
		<p>Thank you for listing with GPbook in 2020.</p>
		<p >To renew your listing in print and online for the <strong>Psychology and Mental Health Directory for 2020,</strong> add or delete practitioners below and complete the payment.</p>
		<p>You can choose to pay separately using the same link and selecting the individuals you wish to pay for.</p>
		<p>After you complete payment, you will receive an email with an invoice and links to update or edit listing details. Please use those links to make changes.</p>
	</div>

