// import axios from 'axios'
import { observable, computed } from 'mobx'
import api from './api'

// Test URL
// http://localhost:3000/?doctors=5c3b0fb10f66b043ae454b32,5c3b0fb00f66b043ae454b08,5c3b0fac0f66b043ae454a28
// http://localhost:3000/?doctors=5c3b0fb10f66b043ae454b32,5c3b0fb00f66b043ae454b08,5c3b0fac0f66b043ae454a28&name=Adrian%20Twarog&email=ahiman@gmail.com&company=VPNAUS%20Pty%20Ltd&phone=0893657777
// 	Viviana Jancso	
// Linda Basson	
// Nick Gerrish
// http://localhost:3081/newadmin/doctors/view/5c3b0fac0f66b043ae454a28
// http://localhost:3081/newadmin/doctors/view/5c3b0fb00f66b043ae454b08

const doctorSchema = { name: "", accountStatus: "unpaid", editable: true, check: true, showCheck: true }

class AppStore {


	api = api
	@observable doctors = []
	@observable error = false

	@observable priceAmount = 15000
	@computed get price() {
		if (this.payableListingCount > 9) {
			return 9000
		}
		if (this.payableListingCount > 1) {
			return 12000
		}
		return this.priceAmount
	}

	@observable purchase = {
		email: "",
		name: "",
		company: "",
		phone: "",
	}
	@observable reference = ""
	@observable showTaxInvoice = false
	@observable renewal = true

	@observable redirected = false

	constructor() {
		let url = new URL(window.location.href);
		let redirected = url.searchParams.get("redirected");
		if (redirected) {
			this.redirected = true
		} else {
			this.getPsychs()
			this.getDetails()
		}
	}

	getPsychs = () => {
		let url = new URL(window.location.href);
		let urlDoctorsIds = url.searchParams.get("doctors");
		if (!urlDoctorsIds || !urlDoctorsIds.length) {
			this.error = true
			this.renewal = false
			this.addPsych()
			return false;
		}
		api.post('/psych/listings', { doctors: urlDoctorsIds.split(",") } )
			.then(({ data }) => {
				if (data && data.length) {
					for (let doctor of data) {
						if (doctor.accountStatus !== 'paid') {
							doctor.showCheck = true
							doctor.check = true
						} 
						if (doctor.accountStatus === 'paid') {
							doctor.showCheck = false
							doctor.check = false
						} 
					}
					this.doctors = [...data]
				}
			})
	}

	getDetails = () => {
		let url = new URL(window.location.href);
		let name = url.searchParams.get("name");
		let company = url.searchParams.get("company");
		let email = url.searchParams.get("email");
		let phone = url.searchParams.get("phone");
		this.purchase = {
			email, name, company, phone
		}
	}

	addPsych = () => {
		this.doctors = [...this.doctors, { ...doctorSchema }]
	}

	removePsych = (index) => {
		this.doctors.splice(index,1);
	}

	@computed get cost() {
		return this.payableListingCount * this.price
	}

	@computed get payableListingCount() {
		return this.doctors.filter(doctor => doctor.check).length
	}


	@observable clinicians = []
	@observable name = ""

	redirectedOnChange = async (e) => {
		const { key, } = e
		
		if (key === "Enter") {
			if (this.name && this.name.length > 1) {
				api.post('/psych/lookup', { keyword: this.name } )
					.then(({ data }) => {
						
					if (data) {
						this.clinicians = data
					}
				})
			}
			else {
				this.clinicians = []
			}
		} 
	}

}

export default AppStore