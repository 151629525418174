import React, { Component } from 'react';
import Payment from './Payment'

class App extends Component {
	render() {
		return (
			<div>
				<Payment />
			</div>
		);
	}
}

export default App;