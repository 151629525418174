import React, { Component } from 'react';
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements';

import { observer, inject } from 'mobx-react'
import { observable } from 'mobx'
import './Checkout.scss'

import DATA_STRIPE from './DATA_STRIPE'
let apiKey
process.env.NODE_ENV === 'development' ? apiKey = DATA_STRIPE.apikey.test : apiKey = DATA_STRIPE.apikey.live

const Checkout =()=>
	<div>
		<StripeProvider apiKey={apiKey}>
			<Elements>
				<CheckoutForm />
			</Elements>
		</StripeProvider>
	</div>

@inject("store")
@observer
class _CheckoutForm extends Component {

	@observable error = false
	@observable errorMessage = "Your card details may be incorrect, please check and try again"
	@observable loading = 0
	@observable checkAllInputsFilled = false

	handleSubmit = (ev) => {
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault();
		// 1. Verifying Card Details

		// Verify all details entered
		if (!(
			this.props.store.purchase.email &&
			this.props.store.purchase.phone &&
			this.props.store.purchase.company &&
			this.props.store.purchase.name
		)) {
			this.loading = 0
			this.checkAllInputsFilled = true
			return
		} 

		this.loading = 1
		// Get Customer Token ID
		this.props.stripe.createToken({ ...this.props.store.purchase }).then(({ token }) => {
			// Purchasing System
			this.loading = 2
			this.error = false
			
			let doctors = this.props.store.doctors.filter(doctor => {
				if (doctor.check) {
					return true
				}
			})

			if (token) {
				this.props.store.api.post('/psych/purchase',
					{
						doctors,
						token,
						purchase: this.props.store.purchase,
						cost: this.props.store.cost,
						price: this.props.store.price,
						payableListingCount: this.props.store.payableListingCount,
					})
					.then(({ data }) => {
						// Purchase complete, timeout to next page
						
						if (data.success) {
							this.props.store.reference = data.customer
							this.loading = 3
							// this.props.store.getPsychs() // Redo this manually
							
							setTimeout(() => {
								this.loading = 4
								this.props.store.showTaxInvoice = true
							}, 2500)

						} else {
							this.loading = 0
							this.error = true
							this.errorMessage = data.message
						}
						
					})
					.catch(err => {
						console.log(err);
						this.error = true;
						this.loading = 0
					})	
			} else {
				this.error = true
				this.loading = 0
			}
		})
		.catch(err => { console.log(err); this.error = true; this.loading = 0 })
	}
	render() {
		return (
			<div className="checkout-holder">
				<h3 style={{marginTop: 0, marginBottom: 15}}>Billing Details</h3>
				<div className="input-group">
					<div className="input-holder name">
						<input
							className={`${this.checkAllInputsFilled && "error"}`}
							type="text"
							placeholder="Full Name"
							value={this.props.store.purchase.name}
							onChange={e => {
								this.props.store.purchase.name = e.target.value
								this.checkAllInputsFilled = false
							}}
						/>
					</div>
					<div className="input-holder company">
						<input
							className={`${this.checkAllInputsFilled && "error"}`}
							type="text"
							placeholder="Company"
							value={this.props.store.purchase.company}
							onChange={e => {
								this.props.store.purchase.company = e.target.value
								this.checkAllInputsFilled = false
							}}
						/>
					</div>
				</div>
				<div className="input-group">
					<div className="input-holder email">
						<input
							className={`${this.checkAllInputsFilled && "error"}`}
							type="text"
							placeholder="Email address"
							value={this.props.store.purchase.email}
							onChange={e => {
								this.props.store.purchase.email = e.target.value
								this.checkAllInputsFilled = false
							}}
						/>
					</div>
					<div className="input-holder phone">
						<input
							className={`${this.checkAllInputsFilled && "error"}`}
							type="text"
							placeholder="Phone"
							value={this.props.store.purchase.phone}
							onChange={e => {
								this.props.store.purchase.phone = e.target.value
								this.checkAllInputsFilled = false
							}}
						/>
						</div>
				</div>
				<CardElement style={style} />
				<h3>Total: ${(this.props.store.cost /100).toFixed(2)} <small>(inc GST)</small></h3>
				{this.loading === 0 && <div className="btn-pay" onClick={this.handleSubmit}>Pay {this.props.store.renewal ? "Renewal" : "Listing"}</div>}
				{this.loading === 1 &&
						<div className="btn-pay processing"><i className="fa fa-cog fa-spin"></i>Verifying Card Details</div>}
				{this.loading === 2 &&
					<div className="btn-pay processing"><i className="fa fa-cog fa-spin"></i>Processing Order</div>}
				{this.loading === 3 &&
					<div>
						<div className="btn-pay success" onClick={this.handleSubmit}><i className="fa fa-check"></i>Order Complete!</div>
						<div >
							<div className="btn-pay processing">Loading Receipt...</div>
						</div>
					</div>}
				{this.error ? <div>
					<div className="btn-pay error">
						{this.errorMessage || "There appears to be a problem"}
					</div>
				</div> : null}
			</div>
		)
	}
}

var style = {
	base: {
	  color: '#32325d',
	  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
	  fontSmoothing: 'antialiased',
	  fontSize: '16px',
	  '::placeholder': {
		color: '#aab7c4'
	  },
	  ':-webkit-autofill': {
		color: '#32325d',
	  },
	},
	invalid: {
	  color: '#fa755a',
	  iconColor: '#fa755a',
	  ':-webkit-autofill': {
		color: '#fa755a',
	  },
	}
  };

const CheckoutForm = injectStripe(_CheckoutForm);


export default Checkout;