import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'

import male from './doctor-male.svg'
import female from './doctor-female.svg'

let imgUrl = 'https://online.gpbook.com.au'

if (process.env.NODE_ENV === 'development') {
	imgUrl = 'http://localhost:3080'
}
if(window.location.hostname === "adrian.gpbook.com.au")  {
	imgUrl = 'http://adrian.gpbook.com.au:3080'
}

@inject('store')
@observer
class Redirected extends Component {
	render() {
		return (
			<div className="redirected">
				<h1>2020 Psychology and Mental Health Directory</h1>
				<h2>Renewal Instructions</h2>
				<p>This page is for practitioners wishing to have their listing in the 2020 directory which is produced both in print and online for distribution to GPs across WA.</p>
				<p>Please <strong>enter in your name below</strong> and select your entry</p>
				<input
					type="text"
					placeholder="Find your entry (Surname)"
					value={this.props.store.name}
					onChange={e=>this.props.store.name=e.target.value}
					onKeyUp={this.props.store.redirectedOnChange}
				/>
				<button onClick={()=>this.props.store.redirectedOnChange({ key: "Enter"})}>Search</button>
				{this.props.store.clinicians.map(clinician => <Clinician key={clinician._id} clinician={clinician} />)}
			</div>
		);
	}
}

const Clinician = ({ clinician }) => <a href={`https://psych.gpbook.com.au/listing/?doctors=${clinician._id}`} className="clinician">
	<div className={`avatar ${clinician.gender}`}
		style={ clinician.image ? {
			backgroundImage: `url(${imgUrl}${clinician.image})`
		} : null}
		></div>
	<div className="details">
		<div className="name">{clinician.name}</div>
		<div className="field">{clinician.field.title}</div>
	</div>
</a>

export default Redirected;