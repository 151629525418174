import React, { Component } from 'react';
import logo from './gpbook-logo.svg'
import moment from 'moment'
import './TaxInvoice.scss'

import { observer, inject } from 'mobx-react'
@inject('store')
@observer
class TaxInvoice extends Component {
	render() {
		const { purchase, cost, price, payableListingCount, reference } = this.props.store
		return (
			<div id="tax-invoice" className="tax-invoice">
				<div className="details">
					<img src={logo} alt="feature" width="92"/>
					<h3>GPbook WA</h3>		
					<div className="detail abn">
						<strong>ABN: 63 139 723 321</strong>
					</div>		
					<div className="detail website">
						<i className="fa fa-fw fa-globe"></i>https://www.gpbook.com.au
					</div>
					<div className="detail address">
						<i className="fa fa-fw fa-home"></i>PO BOX 140 Morley 6943
					</div>
					<div className="detail phone">
						<i className="fa fa-fw fa-envelope"></i>info@gpbook.com.au
					</div>
				</div>
				<h2>Tax Invoice</h2>
				<div className="tax-details">
					<div className="detail">
						<strong>Date:</strong> {moment().format('D/M/YYYY')}
					</div>	
					<div className="detail">
						<strong>Reference:</strong> {reference}
					</div>			
				</div>

				<div className="to-whom">
					{purchase.name && <div className="business-name">{purchase.company}</div>}
					<div className="contact">
						{purchase.name && <div><b>Attention:</b> {purchase.name}</div>}
						{purchase.address && <div>{purchase.address}</div>}
						{purchase.email && <div>{purchase.email}</div>}
						{purchase.phone && <div>{purchase.phone}</div>}
					</div>
				</div>

				<table className="table pricing">
					<thead>
						<tr>
							<th>Product</th>
							<th>Quantity</th>
							<th>Unit Cost</th>
							<th width="150">Price <small>(inc GST)</small></th>
						</tr>

					</thead>
					<tbody>

						<tr>
							<td>
								<strong>Psychology Listing</strong><br />
								1 year listing in the online and book publication.
							</td>
							<td>
								{payableListingCount}
							</td>
							<td>
								${(price/100).toFixed(2)}
							</td>
							<th>
								${(cost/100).toFixed(2)}
							</th>
						</tr>

						<tr>
						<td></td>
						<td></td>
							<td><strong>Tax (GST)</strong></td>
								<td>${(((cost) - (cost / 1.1)) / 100).toFixed(2)}</td>
						</tr>
						<tr>
						<td></td>
						<td></td>
							<td><strong>Total</strong></td>
							<th>${(cost/100).toFixed(2)}</th>
						</tr>

					</tbody>
				</table>

				<div>
					<div><strong>Thank you for your purchase</strong></div>
					If you have any queries, please contact us at info@gpbook.com.au
				</div>

			</div>
		);
	}
}

export default TaxInvoice;