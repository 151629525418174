import axios from 'axios'

let baseURL = 'https://online.gpbook.com.au/api/guest/'

if (process.env.NODE_ENV === 'development') {
	baseURL = 'http://localhost:3080/api/guest/'
}
if(window.location.hostname === "adrian.gpbook.com.au")  {
	baseURL = 'http://adrian.gpbook.com.au:3080/api/guest/'
}

let api = axios.create({
	baseURL,
});

export default api